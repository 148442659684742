

// 转化算法
export const fmtPosition = function (rawData) {
  // https://www.opengps.cn/Map/Tools/GPSConvert.aspx
  var du = "0";
  if (rawData.indexOf("°") != -1) {
    du = rawData.substring(0, rawData.indexOf("°"));
  }
  var fen = "0";
  if (rawData.indexOf("°") != -1) {
    var fen = rawData.substring(rawData.indexOf("°") + 1, rawData.indexOf("#"));
  }
  var miao = "0";
  if (rawData.indexOf("#") != -1) {
    var miao = rawData.substring(rawData.indexOf("#") + 1).replace("\@", "");
  }
  // console.log('dudfasd', du, fen, miao)
  var tempfen = parseFloat(fen) + parseFloat(miao / 60);
  var tempdu = parseFloat(tempfen / 60) + parseFloat(du);
  return tempdu
}

// 百度地图坐标-十二片区
export const areaData = [
  // 草滩以西海域
  '109.58887280752178,21.320208044198054;109.57732835662834,21.30972278616075;109.57741720184531,21.301226815545295;109.60813176999558,21.301000336226313;109.60793354003049,21.320017631655542',
  '109.49601735591547,21.23382178388624;109.49058942460928,21.232499304581335;109.48712318808636,21.21589107450247;109.49058281187978,21.227265353395;109.52038705346601,21.23378250415168', // 乐民港以西海域片区
  '109.44401191955944,21.06236132377048;109.44421319849508,21.04442167199979;109.47325053937263,21.04436128695345;109.47305393986939,21.062304391427958', // 江洪港以西海域片区
  '109.79186722711168,20.521887578189613;109.79178579464036,20.533747963465913;109.80498651033376,20.533914219617234;109.80778652839969,20.522070767900345', // 乌石港海域片区
  '109.84471057025945,20.424860526743338;109.83009139306185,20.42503347313213;109.82991114812303,20.44872138054528;109.84453300017755,20.448595747051094', // 流沙湾海域片区
  '110.47973953583856,20.903030006499623;110.44512135465361,20.904224808412195;110.4364806943074,20.923252641841394;110.46334878159091,20.91514078802886', // 东海岛东南海域片区
  '110.91918643061227,21.372217582228824;110.94989178641941,21.36138671653068;110.98201461107547,21.368614548880565;110.97965402113397,21.3784742597965', // 吴川王村港海域片区
  '109.82125675373591,20.487492177172534;109.83248826385687,20.487304592623037;109.83248685329934,20.497827221733132;109.82125533805392,20.498014810964577', // 覃斗以西海域片区
  '110.39789555213177,20.965607584957816;110.39790351181968,20.95449780776845;110.40901716556456,20.954531914493952;110.40900920569024,20.96564168684267', // 东里以东海片区
  '109.76733541068162,21.38995787708625;109.76414376304703,21.398222052336;109.79196182956129,21.413022984437877;109.79546207702896,21.399709052704917', // 角头沙以北海域片区
  '110.53225694747661,20.313513741559753;110.5680748426582,20.32175710491554;110.5713796498968,20.29962527584732;110.53292960801525,20.29495467188947', // 罗斗沙深海网箱养殖海域片区
  '110.3154138512314,20.227157295732475;110.30555402940804,20.240828487408567;110.33717227132728,20.24592024343435;110.33951953042656,20.231160812033373' // 排尾角深海网箱养殖海域片区
]

// 自定义中标记点位置
export const pointIcon = [
  // 一核
  { name: '特呈岛海域深海网箱产业核心区', point: '110.434801,21.167515' }, // 特呈岛海域深海网箱产业核心区
  // 双翼
  { name: '“西翼”北部湾区域', point: '109.44257605292347,20.81666171148689' }, // “西翼”北部湾区域
  { name: '“东翼”南海区域', point: '110.5708385172534,20.80513868423858' }, // “东翼”南海区域
  // 三港
  { name: '硇洲示范性渔港', point: '110.569775,20.881941' }, // 硇洲示范性渔港
  { name: '乌石一级渔港', point: '109.838134,20.551546' }, // 乌石一级渔港
  { name: '草潭二级渔港', point: '109.784494,21.272188' }, // 草潭二级渔港
  // 四心
  { name: '海洋渔业加工中心', point: '110.407532,21.242321' }, // 海洋渔业加工中心
  { name: '海水产品物流与贸易中心', point: '110.369052,21.161016' }, // 海水产品物流与贸易中心
  { name: '优质渔业饲料产业中心', point: '110.312177,21.229769' }, // 优质渔业饲料产业中心
  { name: '深海网箱养殖品种繁育中心', point: '110.386851,20.63899' }, // 深海网箱养殖品种繁育中心
  // 十二片区
  { name: '草潭以西海域', point: '109.5938368912317,21.309910628079958' }, // 草滩以西海域
  { name: '乐民港以西海域片区', point: '109.4949869501129,21.23104099280266' }, // 乐民港以西海域片区
  { name: '江洪港以西海域片区', point: '109.4579887375345,21.053181721400698' }, // 江洪港以西海域片区
  { name: '乌石港海域片区', point: '109.79894010951001,20.528017164324314' }, // 乌石港海域片区
  { name: '流沙湾海域片区', point: '109.83714860791211,20.437032141417653' }, // 流沙湾海域片区
  { name: '东海岛东南海域片区', point: '110.45215510841395,20.91171867857938' }, // 东海岛东南海域片区
  { name: '吴川王村港海域片区', point: '110.95513939558334,21.370991479572343' }, // 吴川王村港海域片区
  { name: '覃斗以西海域片区', point: '109.82666546370557,20.492945997117243' }, // 覃斗以西海域片区
  { name: '东里以东海片区', point: '110.40337092570397,20.96024964200324' }, // 东里以东海片区
  { name: '角头沙以北海域片区', point: '109.78001780265448,21.399864975035207' }, // 角头沙以北海域片区
  { name: '罗斗沙深海网箱养殖海域片区', point: '110.55106887859023,20.30644424509704' }, // 罗斗沙深海网箱养殖海域片区
  { name: '排尾角深海网箱养殖海域片区', point: '110.32449459074294,20.23638499321314' } // 排尾角深海网箱养殖海域片区
]

// 未转化的经纬度
// let seaArr = [
//     '109°34#41.90@,21°19#00.46@;109°34#00.37@,21°18#22.12@;109°34#00.66@,21°17#51.53@;109°35#50.97@,21°17#52.46@;109°35#50.32@,21°19#00.93@', // 草滩以西海域
//     '109°29#06.58@,21°13#50.13@;109°28#47.02@,21°13#26.26@;109°28#47.03@,21°13#45.10@;109°28#34.58@,21°12#45.11@;109°30#34.59@,21°13#50.13@', // 乐民港以西海域片区
//     '109°25#59.36@,21°03#30.27@;109°26#00.04@,21°02#25.68@;109°27#44.57@,21°02#26.64@;109°27#43.91@,21°03#31.23@', // 江洪港以西海域片区
//     '109°46#51.84@,20°31#48.01@;109°46#52.18@,20°31#05.30@;109°47#49.63@,20°31#05.71@;109°47#39.49@,20°31#48.34@', // 乌石港海域片区
//     '109°49#09.22@,20°26#42.44@;109°49#09.87@,20°25#17.13@;109°50#02.44@,20°25#17.49@;109°50#01.80@,20°26#42.97@', //  (五)流沙湾海域片区
//     '110°25#31.57@,20°55#10.60@;110°26#02.62@,20°54#02.66@;110°28#07.40@,20°53#59.02@;110°27#08.20@,20°54#42.73@', //  (六)东海岛东南海域片区
//     '110°54#29.51@,21°22#08.32@;110°56#19.69@,21°21#30.24@;110°58#15.23@,21°21#54.48@;110°58#06.70@,21°22#30.14@', //  七)吴川王村港海域片区
//     '109°49#18.6@,20°29#39.5@;109°48#38.2@,20°29#39.5@;109°49#18.6@,20°29#1.6@;109°48#38.2@,20°29#1.6@', //(八)覃斗以西海域片区
//     '110°23#13@,20°57#42@;110°23#53@,20°57#2@;110°23#13@,20°57#2@;110°23#53@,20°57#42@', // 九)东里以东海片区
//     '109°45#23.29@,21°23#11.93@;109°45#11.76@,21°23#41.85@;109°46#52.30@,21°24#33.63@;109°47#04.92@,21°23#45.57@', //十)角头沙以北海域片区
//     '110°31#17.452@,20°18#34.604@;110°33#26.324@,20°19#06.423@;110°33#38.232@,20°17#46.879@;110°31#19.919@,20°17#27.775@', // 十一)罗斗沙深海网箱养殖海域片区
//     '110°17#41.244@,20°14#12.919@;110°19#34.538@,20°14#32.762@;110°19#42.918@,20°13#39.626@;110°18#16.5245@,20°13#24.179@' //  十ニ)排尾角深海网箱养殖海域片区
// ]
// for(let i = 0; i < seaArr.length;i++){
//     let temp = seaArr[i]
//     let arr = temp.split(';')
//     let stringArr = Array.from(arr).reduce(function(o, t){
//         let r = t.split(',')
//         // console.log('reduce', t, o, r)
//         return (o ? (o + ';') : o) + fmtPosition(r[0]) + ',' + fmtPosition(r[1])
//     }, '')
//     // console.log(stringArr)
//      seaArr[i]  = stringArr
// }
//     console.log('finally', JSON.stringify(seaArr, null, '\t'))


// 转化后的度数
// let DataSea = [
//   "109.57830555555556,21.316794444444444;109.56676944444445,21.306144444444445;109.56685,21.29764722222222;109.59749166666667,21.297905555555555;109.59731111111111,21.316925",
//   "109.48516111111111,21.230591666666665;109.47972777777778,21.223961111111112;109.47973055555556,21.229194444444445;109.47627222222222,21.212530555555556;109.50960833333333,21.230591666666665",
//   "109.43315555555556,21.058408333333333;109.43334444444444,21.040466666666667;109.46238055555555,21.040733333333332;109.46219722222222,21.058675",
//   "109.78106666666666,20.530002777777778;109.78116111111112,20.51813888888889;109.79711944444445,20.51825277777778;109.79430277777777,20.530094444444444",
//   "109.81922777777778,20.44512222222222;109.81940833333333,20.421425;109.83401111111111,20.421525;109.83383333333333,20.445269444444445",
//   "110.42543611111111,20.91961111111111;110.4340611111111,20.90073888888889;110.46872222222223,20.899727777777777;110.45227777777778,20.911869444444445",
//   "110.90819722222223,21.36897777777778;110.93880277777778,21.3584;110.97089722222222,21.365133333333333;110.96852777777778,21.375038888888888",
//   "109.82183333333333,20.494305555555556;109.81061111111111,20.494305555555556;109.82183333333333,20.483777777777778;109.81061111111111,20.483777777777778",
//   "110.38694444444444,20.961666666666666;110.39805555555556,20.950555555555557;110.38694444444444,20.950555555555557;110.39805555555556,20.961666666666666",
//   "109.75646944444445,21.386647222222223;109.75326666666666,21.394958333333335;109.78119444444444,21.409341666666666;109.7847,21.395991666666667",
//   "110.52151444444445,20.30961222222222;110.55731222222222,20.318450833333333;110.56062,20.296355277777778;110.52219972222223,20.291048611111112",
//   "110.29479,20.236921944444443;110.32626055555555,20.24243388888889;110.32858833333333,20.227673888888887;110.30459013888888,20.223383055555555"
//   ]

//   let newArr = []

//   //坐标转换完之后的回调函数
//   let translateCallback = function (data, idx){
//   if(data.status === 0) {
//       let o = newArr[idx]
//       newArr[idx] = (o ? (o + ';') : '') + data.points[0].lng + ',' + data.points[0].lat
//       console.log('translateCallback', data, newArr)
//       console.log('finally', JSON.stringify(newArr, null, '\t'))
//       var marker = new BMapGL.Marker(data.points[0]);
//       // bm.addOverlay(marker);
//       var label = new BMapGL.Label("转换后的百度坐标（正确）",{offset:new BMapGL.Size(10, -10)});
//       marker.setLabel(label); //添加百度label
//       // bm.setCenter(data.points[0]);
//   }
//   }

//   for(let i = 0; i < DataSea.length;i++){
//       let temp = DataSea[i]
//       let arr = temp.split(';')
//       let stringArr = Array.from(arr).reduce(function(o, t){
//           let r = t.split(',')
//           let ggPoint = new BMapGL.Point(r[0],r[1]);
//           let convertor = new BMapGL.Convertor();
//           let pointArr = [];
//           pointArr.push(ggPoint);
//           convertor.translate(pointArr, COORDINATES_WGS84, COORDINATES_BD09, (data) => {translateCallback(data, i)})
//           // console.log('reduce', t, o, r)
//           return o
//       }, '')
//       // console.log(stringArr)
//       // seaArr[i]  = stringArr
//   }

/**
 * 坐标常量说明：
 * COORDINATES_WGS84 = 1, WGS84坐标
 * COORDINATES_WGS84_MC = 2, WGS84的平面墨卡托坐标
 * COORDINATES_GCJ02 = 3，GCJ02坐标
 * COORDINATES_GCJ02_MC = 4, GCJ02的平面墨卡托坐标
 * COORDINATES_BD09 = 5, 百度bd09经纬度坐标
 * COORDINATES_BD09_MC = 6，百度bd09墨卡托坐标
 * COORDINATES_MAPBAR = 7，mapbar地图坐标
 * COORDINATES_51 = 8，51地图坐标
*/